@use "sass:math";

.block-acordions {
    --font-size-title: var(--font-size-large);
    --font-size-body: var(--font-size-base);
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-xs);
    --max-width: #{math.div(920px, 16px) * 1rem};
    --max-width-body: #{math.div(620px, 16px) * 1rem};

    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-base);
        --font-size-body: var(--font-size-xsmall);
        --padding-accordions: 0;
    }
}

.block-acordions {
    padding: var(--padding-v) var(--padding-h);

    @include font-sans();

    &__ul {
        list-style: none;
        max-width: var(--max-width);
        margin: 0 auto;
    
        > li {
            border-top: 1px solid currentColor;
    
            &:last-child { border-bottom: 1px solid currentColor; }
        }
    
        //ACORDEON
        article {
            --duration: 0.4s;
            --ease: var(--ease-in-out-quad);
        }
    
        article > button {
            --rot: 0deg;
            --strokeCross: #{math.div(2px, 16px) * 1rem};
            
            @include basic-a();
            background-color: transparent;
            color: var(--primary-color);
            position: relative;
            width: 100%;
            padding: var(--padding-xs) 0;
            font-size: var(--font-size-title);
            line-height: 1;
            text-align: left;
    
            > span {
                position: absolute;
                right: 0.1em;
                top: 50%;
                width: var(--padding-s);
                height: var(--padding-s);
    
                transform-origin: 50% 50%;
                transform: translate3d(0, -50%, 0) rotate(var(--rot));
    
                transition: transform 0.4s var(--ease);
    
                &::after,
                &::before {
                    content: '';
                    position: absolute;
                }
    
                &::after {
                    top: 0;
                    left: 50%;
                    width: var(--strokeCross);
                    height: 100%;
                    transform: translateX(-50%);
                    background: currentColor;
                }
    
                &::before {
                    top: 50%;
                    left: 0;
                    width: 100%;
                    height: var(--strokeCross);
                    transform: translateY(-50%);
                    background: currentColor;
                }
            }
        }
    
        article[aria-expanded='false'] {
            visibility: visible;
            pointer-events: initial;
        }
        
        article[aria-expanded='true'] > button {
            --rot: 135deg;
        }
    
        article > div {
            max-width: var(--max-width-body);
            padding: var(--padding-xs) 0 var(--padding-s);
        }
    }
}
