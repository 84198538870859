.block-legal {
    --font-size-title: var(--font-size-xl);
    --font-size-subtitle: var(--font-size-l);
    --font-size-third: var(--font-size-m);
    --font-size: var(--font-size-base);
    --padding-v: calc(var(--header-height) * 1.5);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-xl);
        --font-size-subtitle: var(--font-size-l);
        --font-size-third: var(--font-size-m);
        --font-size: var(--font-size-s);
        --padding-v: var(--padding-xl);
        --padding-h: var(--padding-s);
    }
}

.block-legal {
    @include font-sans();

    font-size: var(--font-size);
    padding: var(--padding-v) var(--padding-h);

    h1 {
        @include font-sans(1);
        font-size: var(--font-size-title);
        margin-bottom: 1em;
    }
    
    h2 {
        @include font-sans();
        font-size: var(--font-size-subtitle);
        margin-bottom: 1em;
    }

    h3 {
        @include font-sans();
        font-size: var(--font-size-third);
        margin-bottom: 1em;
    }

    p,
    ul {
        margin-bottom: var(--padding-s);
        max-width: rem(650);
        
        + h2 {
            margin-top: 1em;
        }
        
        + h3 {
            margin-top: 1em;
        }
    }

    ul {
        padding-left: var(--padding-s);

        li {
            list-style: initial;
        }
    }

    a {
        color: var(--primary-color);
        word-break: break-word;
    }

    @media (max-width: $smartphone) {
        h1 {
            margin-bottom: 1em;
        }
    }
}
