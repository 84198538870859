.widget-landing {
    @media (min-width: $smartphone) {
        --font-size: var(--font-size-l);
        --font-size-right: var(--font-size-s);
        --padding: var(--header-height) var(--padding-s) var(--padding-m);
    }

    @media (max-width: $smartphone) {
        --font-size: 16px;
        --font-size-right: var(--font-size-xs);
        --padding: calc(var(--header-height) + var(--padding-m)) var(--padding-xs) var(--padding-m);
    }

    position: relative;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    background-color: black;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: $smartphone) {
        align-items: flex-start;
    }

    padding: var(--padding);

    a {
        @include basic-a();
        color: currentColor;

        @media (max-width: $smartphone) { 
            text-decoration: underline;
        }
    }
    
    @include isCursor() {
        a:hover {
            text-decoration: underline;
        }
    }

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        opacity: .9;

        @media (max-width: $smartphone) {
            opacity: .6;
        }
    }

    .content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 2rem;

        @media (max-width: $smartphone) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }
    }

    .left {
        flex: 0 0 60%;
        position: relative;
        @include font-sans();
        font-size: var(--font-size);
        color: var(--primary-color);

        @media (max-width: $smartphone) { 
            br {
                display: block;
                margin: 1em 0;
                line-height: 1em;
                content: ' ';

                &:first-of-type {
                    display: none;
                }
            }
        }
    }

    .right {
        flex: 0 0 30%;
        position: relative;
        @include font-sans();
        font-size: var(--font-size-right);
        color: var(--primary-color);
    }
}
