@use "sass:math";

#Footer {      
    --width-sizes: 40%;

    @media (min-width: $smartphone) {
        --font-size: var(--font-size-s);
        --padding: var(--padding-s);
        --size-simbolo: #{rem(30)};
        --padding-sizes: #{rem(220)};
    }

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-s);
        --padding: var(--padding-s);
        --size-simbolo: #{rem(20)};
        --padding-sizes: 0;
    }
}

#Footer {
    @include font-sans(1);

    .body-legal & {
        position: relative !important;
        left: auto;
        bottom:auto;
    }

    position: fixed;
    @include z-index($z-index-header);
    left: 0;
    bottom:0;
    width: 100%;

    font-size: var(--font-size);
    padding: var(--padding);
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    
    a {
        @include basic-a();
        color: inherit;

        @include isCursor () {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .left, .right {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        width: var(--width-sizes);
        flex: 1 1 var(--width-sizes);
    }

    .simbolo {
        position: relative;
        flex: 0 0 var(--size-simbolo);
        width: var(--size-simbolo);
        height: auto;
        margin: 0 var(--padding-sizes);

        svg {
            display: block;
            fill: var(--primary-color);
            width: 100%;
            height: auto;
        }
    }
}

@media (max-width: $smartphone) {
    #Footer {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .left {
            order: 1;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > div,
            a {
                padding: .4em 0;
            }
        }
    
        .right {
            order: 2;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > * {
                padding: .4em 0;

                a {
                    text-decoration: underline;
                }
            }

            margin-bottom:  2rem;
        }
    
        .simbolo {
            order: 3;
            
        }
    }

    
}