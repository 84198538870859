@use "sass:math";

.next-project {
    --font-size: var(--font-size-xxlarge);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xlarge);
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-m);
    }
}

.next-project {
    background-color: var(--white);
    
    a {
        @include font-sans();
        @include basic-a();
        color: var(--black);
        display: block;
        font-size: var(--font-size);
        padding: var(--padding-v) var(--padding-h);
    }
}