.widget-webgl-image-transition {
    height: 100vh;
    margin-bottom: 25vh;
    position: relative;
    width: 100vw;

    display: flex;
    align-items: center;
    justify-content: center;
    
    .target {
        @include z-index($z-index-wrap);
        background-color: white;
        opacity: 0;
        position: relative;
        width: 50vw;
    }

    .target:last-child {
        position: absolute;
    }
}
