.block-slider-horizontals-scroll {
    --font-size: var(--font-size-xxlarge);

    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-medium);
    }
}

.block-slider-horizontals-scroll {
    position: relative;
    width: 100vw;
    overflow: hidden;

    
    .holder {
        display: flex;
        height: 100%;
        width: auto;
    }
    
    .slide {
        @include font-sans();
        color: var(--primary-color);
        font-size: var(--font-size);
        width: 100vw;

        align-items: center;
        display: flex;
        justify-content: center;
    }
    
    @media (max-width: $smartphone) {
        > div {
            height: 100%;
            width: auto;
            overflow: hidden;
        }

        .holder {
            -webkit-overflow-scrolling: touch;
            overflow: hidden;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .slide {
            min-width: 80vw;

            &:first-child,
            &:last-child { display: none; }

            scroll-snap-align: start;
        }
    }
    
    @media (min-width: $smartphone) {
        height: var(--height);
        
        > div {
            height: 100vh;
            position: relative;
            width: 100vw;
            overflow: hidden;
        }
        
        .holder {
            left: 0;
            position: absolute;
            top: 0;
        }
    }
}