@use "sass:math";

#Scroll-Top {
    --right: var(--padding-xs);
    --bottom: calc(var(--padding-xs) + var(--scrollbar-height));
    
    @include isTouch() {
        --bottom: var(--padding-xs);
    }
}

#Scroll-Top {
    @include basic-a();
    @include z-index($z-index-scroll-top);

    position: fixed;
    right: var(--right);
    bottom: var(--bottom);
}

