.block-project-list {
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: 0;
    }
}

.block-project-list {
    .wrapper {
        position: relative;
    }

    .content {
        padding: var(--padding-v) var(--padding-h);

        ul li {
            margin-bottom: var(--padding-l);
        }
    }

    .filters {
        margin-bottom: var(--padding-m);

        > div {
            display: flex;
        }

        .button {
            margin-right: var(--padding-xs);

            &.--active {
                color: var(--bg);
                background-color: var(--color);
                pointer-events: none;
            }
        }
    }

    @media (max-width: $smartphone) {
        .filters {
            padding-right: var(--padding-h);
            overflow-y: hidden;

            > div {
                display: flex;
                scroll-snap-type: x mandatory;	
                -webkit-overflow-scrolling: touch;
                overflow-x: scroll;
                padding-right: var(--padding-xs);
            }
            
            .button {
                flex-shrink: 0;
                margin-right: var(--padding-xxs);
                
                &:first-child {
                    margin-left: var(--padding-xs);
                }
            }
        }
    }

    @media (min-width: $smartphone) {
        .filters {
            border-bottom: 1px solid var(--grey);
            padding-bottom: var(--padding-m);
        }

        .content {
            ul {
                display: flex;
                flex-wrap: wrap;
                gap: var(--padding-s);
            }

            li {
                margin-bottom: var(--padding-xl);
                width: calc(100% / 3 - 2 * var(--padding-s) / 3);
            }
        }
    }
}
