.widget-modify-stock {
    background-color: var(--white);
    color: var(--black);
    height: 100vh;
    padding: 20% var(--padding-s);
    position: relative;
    width: 100vw;

    .holder {
        margin-top: var(--padding-s);

        li {
            display: flex;
        }
    }
}
