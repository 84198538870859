.widget-webgl {
    height: 100vh;
    position: relative;
    width: 100vw;

    .target {
        @include z-index($z-index-wrap);
        background-color: white;
        height: 100%;
        opacity: .3;
        position: relative;
        width: 50%;
    }

    canvas {
        @include z-index($z-index-3D);
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }

    @media (max-width: $smartphone) {
        .target {
            width: 50%;
        }
    }
}
