.billboard-project {
    --font-size: var(--font-size-xxlarge);
}

.billboard-project {
    h1 {
        @include font-sans();
        font-size: var(--font-size);
    }

    .wrapper {
        align-items: center;
        display: flex;
        padding: var(--header-height) 0 0;
        justify-content: center;
    }
    
    .wrapper,
    .media-holder,
    .overlay {
        height: 90vh;
    }

    .overlay {
        background-color: var(--black);
        left: 0;
        opacity: .5;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .media-holder {
        left: 0;
        padding: 0;
        position: absolute;
        top: 0;
        width: 100%;

        img {
            object-fit: cover;
        }
    }
}
