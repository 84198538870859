.widget-instagram {
    background-color: var(--white);
    color: var(--black);
    font-size: var(--font-size);
    height: 100vh;
    padding: var(--padding-xl);
    width: 100vw;

    .holder {
        display: flex;
        justify-content: center;

        > div {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            
            &:first-child {
                margin-right: var(--padding-s);
            }

            canvas {
                margin-bottom: var(--padding-xs);
            }
        }
    }

    form {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        margin-bottom: var(--padding-m);
    }

    canvas {
        border: 1px solid var(--black);
    }

    @media (min-width: $xlargeScreen) {
        --font-size: 16px;
    }
}

#Instagram__Post {
    height: em(300);
    width: em(300);
}

#Instagram__Story {
    height: em(533);
    width: em(300);
}
