#Sidemenu {
    --bg: var(--white);
    --color: var(--black);
    --font-size: var(--font-size-large);
    --font-size-label: var(--font-size-base);
    --font-size-toggle: var(--font-size-base);
    --padding-top: calc(var(--header-height) + var(--padding-s));
    --padding-bottom: var(--padding-m);
    --padding-h: var(--padding-xs);
}

#Sidemenu {
    background-color: var(--bg);
    color: var(--color);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;

    @include z-index($z-index-sidemenu);

    font-size: var(--font-size);

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    .top {
        @include z-index(1);
        align-items: center;
        display: flex;
        height: var(--header-height);
        justify-content: flex-end;
        padding: var(--padding-xs);
        position: absolute;
        top: 0;
        right: 0;
    }

    .toggle-menu {
        @include font-sans(1.2);

        font-size: var(--font-size-toggle);
        color: inherit;
        background-color: transparent;
        padding: 0;
    }

    & > .content {
        height: calc(var(--vh, 1vh) * 100);
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        
        @include z-index(0);
        
        > div {
            min-height: 100%;
            padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
        }
    }

    .nav-item {
        @include basic-a();
        @include font-sans();

        color: currentColor;
        display: block;
        overflow: hidden;

        &[aria-current="page"] {
            .__text { font-weight: bold; }
        }

        .__text {
            display: block;
            padding: var(--padding-xs) 0;
            position: relative;
        }
    }

    .sublink {
        padding: var(--padding-xs) 0;

        .label,
        li:not(:last-child) { margin-bottom: var(--padding-xs); }

        .label {
            @include font-sans(1);
            font-size: var(--font-size-label);
        }

        .nav-item .__text {
            padding: var(--padding-xs) 0;
        }
    }

    .list li {
        position: relative;
    }
}