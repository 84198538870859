.plaver-video {
    --width: 100%;
    --height: 50vh;
    --fit: cover;
    
    --interface-height: #{rem(20)};
    --interface-gap: #{rem(10)};
    --bg-track: var(--grey);
    --bg-thumb: var(--white);
    
    --opacity-interface: 0;
    
    &.--hide-interface {
        --opacity-interface: 0;
        cursor: none;
    }
    &.--show-interface { --opacity-interface: 1; }
    &.--hide-interface.--pause { --opacity-interface: 1; } // En pausa se fuerza el show

    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: 0;
    }
}

#scene-three {
    pointer-events: none;
}

.plaver-video {
    width: var(--width);
    height: var(--height);

    > video {
        width: 100%;
        height: 100%;
        object-fit: var(--fit);
        object-position: center center;
    }
}

.plaver-video__interface {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: var(--interface-height);
    display: flex;
    padding: 0 var(--interface-gap);
    gap: var(--interface-gap);
    opacity: var(--opacity-interface);
    @include font-sans();

    > button {
        flex: 0;
        background-color: transparent;
    }

    .plaver-video-full__progress {
        flex: 1 0 350px;
        position: relative;

        > div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .track {
            background-color: var(--bg-track);
        }

        .thumb {
            transform-origin: 0 0;
            background-color: var(--bg-thumb);
        }
    }

    .plaver-video__timer {
        flex: 0;
        background-color: transparent;
    }
}

.plaver-video__chapters {
    position: absolute;
    top: 0;
    width: 250px;
    padding: 100px 10px 10px;
    background-color: rgba(0, 0, 0, 0.4);
    list-style: none;
    opacity: var(--opacity-interface);
    @include font-sans();

    button {
        background-color: transparent;
        padding: 10px;
    }
}
