@use "sass:math";

#Header {
    @media (min-width: $smartphone) {
        --padding: var(--padding-s);
    }

    @media (max-width: $smartphone) {
        --padding: var(--padding-xs);
    }

    position: fixed;
    @include z-index($z-index-header);
    width: 100%;
    height: auto;
    padding: var(--padding);
    
    h1 {
        display: block;
        width: 100%;
        height: auto;
    }

    svg {
        display: block;
        width: 100%;
        height: auto;
        fill: var(--primary-color);
    }
}
