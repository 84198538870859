.block-news-list {
    --font-size-top-1: var(--font-size-xlarge);
    --font-size-top-2: var(--font-size-base);
    --padding-top-v: var(--padding-xl);
    --padding-top-h: var(--padding-xxl);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --padding-top-v: var(--padding-m);
        --padding-top-h: var(--padding-s);
        --padding-v: var(--padding-l);
        --padding-h: 0;
    }
}

.block-news-list {
    padding: var(--padding-v) var(--padding-h);
    
    .list li {
        margin-bottom: var(--padding-l);
        
        a {
            background-color: var(--white);
            color: var(--black);
            display: block;
            height: rem(400);
        }
    }
    
    .pagination ol {
        display: flex;

        li {
            margin-right: rem(20);
        }

        a {
            color: var(--white);
        }
    }

    @media (min-width: $smartphone) {
        .list {
            display: flex;
            flex-wrap: wrap;
            gap: var(--padding-s);
    
            li {
                margin-bottom: var(--padding-xl);
                width: calc(100% / 3 - 2 * var(--padding-s) / 3);
            }
        }
    }
}
